export { TaskTypes } from "@shared/types/TaskTypes";
export type { FilterOptionsUpdateNotification } from "./FilterOptionsUpdateNotification";
export type { ResourceGroupingMapping } from "./ResourceGroupingMapping";
export type { TaskType } from "@shared/types/TaskTypes";
export type { TaskFilter } from "./TaskFilter";
export type { WindowWithState } from "./WindowWithState";

declare global {
  interface Window { SEEDTIME_FEATURES: string[]; }
}
