import { useState } from "react";
import { func, string } from "prop-types";
import { Modal } from "antd-legacy";
import ReactDOM from "react-dom";

const TaskMoveWarning = ({ warning, moveTask, cancelMove }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const closeModal = () => {
    setIsModalVisible(false);
    ReactDOM.unmountComponentAtNode(document.getElementById("task-modal"));
  };

  const handleCancel = () => {
    cancelMove();
    closeModal();
  };

  const handleOk = () => {
    moveTask();
    closeModal();
  };

  return (
    <Modal
      title="Warning"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div dangerouslySetInnerHTML={{ __html: warning }} />
    </Modal>
  );
};

TaskMoveWarning.propTypes = {
  cancelMove: func.isRequired,
  moveTask: func.isRequired,
  warning: string.isRequired
};

export default TaskMoveWarning;
