import { isEmpty, intersection, uniq } from "lodash";

import { parseTaskFilter } from "helpers/taskFilterSerialization";

function split(s) {
  return s.toLowerCase().trim().split(/\W+/);
}

class CalendarToggleTracker {
  constructor() {
    this.setCropNameFilter("");
    this._taskFilter = parseTaskFilter(localStorage.getItem("calendar-task-filter"));
    this.selectedPlantingLocationIds = [];
    this.selectedLayerGroupIds = [];
    this.groupingMapping = {};
  }

  // resource titles (crop names)
  isResourceTitleVisible(resourceTitle) {
    return this._cropNameFilters.some((cropNameFilter) => {
      return split(resourceTitle).some((resourceWord) =>
        resourceWord.startsWith(cropNameFilter)
      );
    });
  }

  isResourceSelectedByFilter(plantingId) {
    const isLayerGroupMatched =
      isEmpty(this.selectedLayerGroupIds) ||
      !isEmpty(
        intersection(
          this.selectedLayerGroupIds,
          this.groupingMapping[plantingId]?.layerGroupIds || []
        )
      );

    const isPlantingLocationMatched =
      isEmpty(this.selectedPlantingLocationIds) ||
      !isEmpty(
        intersection(
          this.selectedPlantingLocationIds,
          this.groupingMapping[plantingId]?.plantingLocationIds || []
        )
      );

    return isLayerGroupMatched && isPlantingLocationMatched;
  }

  setCropNameFilter(query) {
    this._cropNameFilters = split(query);
  }

  // tasks
  setTaskFilter(taskFilter) {
    this._taskFilter = taskFilter;
  }

  isTaskVisible(taskType) {
    return this._taskFilter[taskType];
  }

  // plantings
  hidePlanting(plantingId) {
    this._visiblePlantings.delete(plantingId);
  }

  showPlanting(plantingId) {
    this._visiblePlantings.add(plantingId);
  }

  isPlantingVisible(plantingId) {
    return this._visiblePlantings.has(plantingId);
  }

  // frost toggles
  hideFrostToggle(type) {
    this._visibleFrostZones[type] = false;
  }

  showFrostToggle(type) {
    this._visibleFrostZones[type] = true;
  }

  isFrostToggleVisible(type) {
    return this._visibleFrostZones[type];
  }

  // private
  get _visibleFrostZones() {
    return window.VisibleToggles.frostZones;
  }

  get _visiblePlantings() {
    return window.VisibleToggles.visiblePlantings;
  }
}

class State {
  constructor() {
    this._lastDraggedEvent = {
      instanceId: null,
      start: null
    };

    this.calendarToggleTracker = new CalendarToggleTracker();
    this.markDirty();
    this._toNotify = [];
    this._filterOptionsUpdateSubscriptions = [];
    this._reloading = false;
    this._data = {};

    this._onChangeSidebar = [];
    // TODO: this is kind of messy, but it'll work in a pinch
    this.fetchQueryParams = "";

    this.plantingLocationFilterOptions = [];
    this.layerGroupFilterOptions = [];
  }

  get selectedPlantingLocationIds() {
    return this.calendarToggleTracker.selectedPlantingLocationIds;
  }

  get selectedLayerGroupIds() {
    return this.calendarToggleTracker.selectedLayerGroupIds;
  }

  subscribeToFilterOptionsUpdate(callback) {
    this._filterOptionsUpdateSubscriptions.push(callback);
  }

  unsubscribeFromFilterOptionsUpdate(callback) {
    this._filterOptionsUpdateSubscriptions =
      this._filterOptionsUpdateSubscriptions.filter(
        (subscription) => subscription !== callback
      );
  }

  notifyFilterOptionsUpdateSubscribers() {
    const notification = {
      plantingLocationFilterOptions: this.plantingLocationFilterOptions,
      selectedPlantingLocationIds: this.selectedPlantingLocationIds,
      layerGroupFilterOptions: this.layerGroupFilterOptions,
      selectedLayerGroupIds: this.selectedLayerGroupIds
    };

    this._filterOptionsUpdateSubscriptions.forEach(callback => callback(notification));
  }

  setGroupingMapping(mapping) {
    this.calendarToggleTracker.groupingMapping = mapping;
  }

  onSelectedPlantingLocationIdsChange(selectedIds) {
    this.calendarToggleTracker.selectedPlantingLocationIds = selectedIds;

    this.notifyFilterOptionsUpdateSubscribers();
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  onSelectedLayerGroupIdsChange(selectedIds) {
    this.calendarToggleTracker.selectedLayerGroupIds = selectedIds;

    this.notifyFilterOptionsUpdateSubscribers();
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  setLastDraggedEvent(instanceId, start) {
    this._lastDraggedEvent = { instanceId, start };
  }

  getLastDraggedEvent() {
    return this._lastDraggedEvent;
  }

  listenSidebarFiltersOrToggles(func) {
    this._onChangeSidebar.push(func);
  }

  markDirty() {
    this._dirty = true;
  }

  getStoredEvents(year, month) {
    const key = `${year}-${month}`;
    const events = this._data[key] || [];

    return events;
  }

  async fetchPlantingGroupings(plantingIds) {
    const url = new URL("/planting_groupings", window.location.origin);

    url.searchParams.append("plantingIds", plantingIds);

    const resp = await fetch(url);
    const json = await resp.json();

    this.plantingLocationFilterOptions = json.plantingLocations;
    this.layerGroupFilterOptions = json.layerGroups;

    this.setGroupingMapping(json.groupingMapping);
    this.notifyFilterOptionsUpdateSubscribers();
  }

  async reload() {
    if (this._reloading) {
      return;
    }

    this._reloading = true;

    // TODO: can we inject a rails route for the URL?
    const resp = await fetch(
      "/calendar_events/all_by_month_and_year.json?" + this.fetchQueryParams
    );
    this._data = await resp.json();

    this.fetchPlantingGroupings(this._collectPlantingIds());

    this._dirty = false;
    this._reloading = false;
    for (const [successFunc, year, month] of this._toNotify) {
      successFunc(this.getStoredEvents(year, month));
    }
    this._toNotify = [];
  }

  _collectPlantingIds() {
    return uniq(
      Object.values(this._data)
        .flat()
        .map(({ planting }) => planting.toString())
    );
  }

  getCalendarEvents(year, month, successFunc) {
    if (this._dirty) {
      // TODO: error handling
      this.reload().catch((_) => undefined);
      this._toNotify.push([successFunc, year, month]);
    } else {
      return successFunc(this.getStoredEvents(year, month));
    }
  }

  getAllCalendarControllers() {
    const calendars = document.getElementsByClassName("calendar");
    const controllers = [];

    Array.from(calendars).forEach((el) => {
      const controller = el.year || el.month || el.timeline;

      if (controller) controllers.push(controller);
    });

    return controllers;
  }

  getAllCalendars() {
    return this.getAllCalendarControllers().map((controller) => controller.calendar);
  }

  // TODO: this seems to yield hundreds of events (500 on my test), i'm not sure there's that many on the page.
  //       let's make sure this isn't duplicating events
  getAllEventsFromAllCalendars() {
    return this.getAllCalendars().flatMap((calendar) => calendar.getEvents());
  }

  shouldShowCalendarEvent(event) {
    const { planting, taskType, resourceTitle } = event;

    if (planting === undefined || taskType === undefined) {
      console.error(
        "Event does not have `planting` and/or `taskType` in `State.shouldShowCalendarEvent`",
        event
      );

      return true;
    }

    return (
      this.calendarToggleTracker.isResourceSelectedByFilter(planting) &&
      this.calendarToggleTracker.isResourceTitleVisible(resourceTitle) &&
      this.calendarToggleTracker.isPlantingVisible(planting) &&
      this.calendarToggleTracker.isTaskVisible(taskType)
    );
  }

  _hideOrDisplayAllEvents() {
    for (const calEvent of this.getAllEventsFromAllCalendars()) {
      const shouldShow = this.shouldShowCalendarEvent(calEvent.extendedProps);

      calEvent.setProp("display", shouldShow ? "auto" : "none");
    }
  }

  _hideOrDisplayFrostEvents() {
    for (const calEvent of this.getAllEventsFromAllCalendars()) {
      const { frostType } = calEvent.extendedProps;
      if (frostType === undefined) {
        continue;
      }
      const shouldShow = this.calendarToggleTracker.isFrostToggleVisible(frostType);
      calEvent.setProp("display", shouldShow ? "background" : "none");
    }
  }

  showFrostToggle(type) {
    this.calendarToggleTracker.showFrostToggle(type);
    this._hideOrDisplayFrostEvents();
  }

  hideFrostToggle(type) {
    this.calendarToggleTracker.hideFrostToggle(type);
    this._hideOrDisplayFrostEvents();
  }

  showToggle(type, value) {
    switch (type) {
      case "planting":
        this.calendarToggleTracker.showPlanting(parseInt(value, 10));
        break;
      default:
        console.error("unknown toggle type", type);
    }
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  hideToggle(type, value) {
    switch (type) {
      case "planting":
        this.calendarToggleTracker.hidePlanting(parseInt(value, 10));
        break;
      default:
        console.error("unknown toggle type", type);
    }
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  setTaskFilter(taskFilter) {
    this.calendarToggleTracker.setTaskFilter(taskFilter);
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  setSearchBox(query) {
    this.calendarToggleTracker.setCropNameFilter(query);
    this._hideOrDisplayAllEvents();
    this._triggerSidebarListeners();
  }

  // TODO: move this to another class?
  reloadAllCalendarViews() {
    this.getAllCalendarControllers().forEach((controller) => controller.reload());
  }

  isPlantingVisible(...args) {
    return this.calendarToggleTracker.isPlantingVisible(...args);
  }

  _triggerSidebarListeners() {
    this._onChangeSidebar.forEach((callback) => callback(this.calendarToggleTracker));
  }
}

export default new State();
