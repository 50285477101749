import { TaskTypes, TaskType } from "types";

const TASK_NAMES: Record<TaskType | "%future added value", string> = {
  [TaskTypes.DirectSeeding]: "Direct Seed",
  [TaskTypes.IndoorSeeding]: "Seeding",
  [TaskTypes.BedPreparation]: "Bed Preparation",
  [TaskTypes.Transplanting]: "Transplanting",
  [TaskTypes.Cultivating]: "Cultivating",
  [TaskTypes.Harvesting]: "Harvesting",
  [TaskTypes.StaleSeedBed]: "Stale Seed Bed",
  "%future added value": "Unknown task type"
};

const humanizeTaskType = (taskType: TaskType | "%future added value"): string =>
  TASK_NAMES[taskType] || TASK_NAMES["%future added value"];

export default humanizeTaskType;
