import { Dropdown, Menu } from "antd-legacy";
import { styled } from "@linaria/react";

import sortIcon from "images/sort.svg";

import { SortKeys, SortOption } from "./types";

const DEFAULT_SORT_OPTIONS: SortOption[] = [
  {
    key: SortKeys.Index,
    name: "By Crop"
  },
  {
    key: SortKeys.LayerGroup,
    name: "By Group"
  },
  {
    key: SortKeys.PlantingLocation,
    name: "By Planting Location"
  },
  {
    key: SortKeys.SeedingDate,
    name: "Seeding Date"
  },
  {
    key: SortKeys.InGroundDate,
    name: "In Ground Date"
  },
  {
    key: SortKeys.HarvestingDate,
    name: "Harvesting Date"
  }
];

const Button = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  cursor: pointer;
`;

type Props = {
  onChange: (key: SortKeys) => void;
  sortOptions?: SortOption[];
};

const TimelineSortPresentation = ({
  onChange,
  sortOptions = DEFAULT_SORT_OPTIONS
}: Props) => {
  const handleMenuCLick = (params: { key: string }) => onChange(params.key as SortKeys);

  const menuItems = sortOptions.map(({ key, name }) => (
    <Menu.Item id={key} key={key} onClick={handleMenuCLick}>
      <span className="label">{name}</span>
    </Menu.Item>
  ));

  return (
    <Dropdown overlay={<Menu>{menuItems}</Menu>}>
      <Button data-testid="task-filter-button">
        <img alt="Sort" src={sortIcon} />
        <span>Sort</span>
      </Button>
    </Dropdown>
  );
};

export { TimelineSortPresentation };
