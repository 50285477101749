import { Modal } from "tailwindcss-stimulus-components";

export default class extends Modal {
  static targets = ["form_wrapper", "select", "submit"];

  connect() {
    this.element[this.identifier] = this;
    super.connect();
  }

  close() {
    this.element.remove();
  }
}
