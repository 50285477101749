/* eslint-disable @typescript-eslint/no-var-requires */
const colors = require("./colors");

// theme variables not defined in antd
module.exports = {
  "border-base": `1px solid ${colors["light-grey"]}`,
  "border-radius": "20px",
  "btn-primary-bg-color-hover": colors["primary-darker"],
  "btn-primary-bg-color-active": colors["primary-darker"],
  "btn-primary-focus-border-color": colors["warning"],
  "color-button-size": "25px",
  "disabled-bg": colors["light-grey"],
  "disabled-color": colors["mid-grey"],
  "heading-font": "Raleway, Helvetica, sans-serif",
  "switch-handle-unchecked-bg-color": colors["mid-grey"],
  "switch-unchecked-bg-color": colors["light-grey"],
  "switch-unchecked-color": colors["mid-grey"],
  "switch-font-size": "13px",
  "text-small-font-size": "13px",
  "text-font": "Inter, Helvetica, sans-serif"
};
