import { ThemeConfig } from "antd";

import colors from "./colors";
import seedtime from "./seedtime";

const theme: ThemeConfig = {
  components: {
    Breadcrumb: {
      linkHoverColor: colors.linkHover,
      linkColor: colors.primary
    },
    Tabs: {
      horizontalItemPadding: "12px 30px",
      itemSelectedColor: "#332b25",
      horizontalMargin: "0 32px 32px 32px"
    }
  },
  token: {
    colorLink: colors.primary,
    colorPrimary: colors.primary,
    colorSuccessText: colors.primary,
    colorTextSecondary: colors["mid-grey"],
    fontFamily: seedtime["text-font"],
    fontSize: 16
  }
};

export default theme;
