import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";

export default class extends Controller {
  static targets = ["fullCalendar"];

  connect() {
    const calendar = new Calendar(this.fullCalendarTarget, {
      plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin],
      droppable: true,
      schedulerLicenseKey: "0514747198-fcs-1606763132",
      editable: true,
      initialView: "yearGrid",
      //  initialView: 'resourceTimelineYear',
      views: {
        yearGrid: {
          type: "dayGrid",
          duration: { days: 30 }
        }
      },
      headerToolbar: false,
      height: 160,
      slotLabelInterval: { weeks: 1 },
      duration: { weeks: 1 },
      resourceAreaColumns: [
        {
          field: "title",
          headerContent: "Crop"
        }
      ],
      resources: [
        {
          id: "peas",
          title: "Peas"
        },
        {
          id: "corn",
          title: "Corn",
          eventColor: "green"
        },
        {
          id: "tomatoes",
          title: "Tomatoes",
          eventColor: "orange"
        }
      ],
      events: [
        {
          resourceId: "peas",
          title: "BedPrep",
          start: "2020-11-01",
          end: "2020-11-08",
          color: "red"
        },
        {
          resourceId: "peas",
          title: "Planting",
          start: "2020-11-20",
          end: "2020-12-01"
        },
        {
          resourceId: "corn",
          title: "Harvest",
          start: "2020-11-25",
          end: "2020-12-05"
        },
        {
          resourceId: "tomatoes",
          title: "Bed-Prep",
          start: "2020-11-12",
          end: "2020-11-17"
        },
        {
          resourceId: "Carrots",
          title: "Plant Carrots",
          start: "2020-11-20",
          end: "2020-11-30"
        },
        {
          resourceId: "carrots",
          title: "Carrots",
          start: "2020-11-30",
          end: "2020-12-5"
        }
      ]
    });
    calendar.render();
  }
}
