import colors from "theme/colors";
import antdTheme from "theme/antd-legacy";
import seedtimeTheme from "theme/seedtime";

export const setRootCSSVariables = () => {
  const body = document.querySelector("body");

  const colorsWithSuffix = Object.fromEntries(
    Object.entries(colors).map(([key, value]) => [`${key}-color`, value])
  );

  Object.entries({ ...colorsWithSuffix, ...antdTheme, ...seedtimeTheme }).map(
    ([name, value]) => {
      body.style.setProperty(`--${name}`, value);
    }
  );
};

window.addEventListener("turbo:load", setRootCSSVariables);
