module.exports = {
  accent: "#ffce50",
  "accent-darker": "#fab349",
  black: "#332B25",
  "dark-grey": "#413E3C",
  error: "#F04F4F",
  frost: "#F1FAFF",
  "hard-frost": "#E1F4FF",
  "light-grey": "#DBDBDB",
  linkHover: "#97cc6e",
  "mid-grey": "#A1A1A1",
  "page-background": "#F6F5F3",
  primary: "#79C048",
  "primary-darker": "#5E9834",
  "primary-light": "#F1F4EB",
  secondary: "#786658",
  success: "#52C048",
  "text-primary": "#332B25",
  warning: "#FAB349",
  white: "#FFFFFF"
};
